import { Outlet } from "react-router";
import { Media, MediaContextProvider } from "../common/media/Media";
import MainNavigationDesktop from "./MainNavigationDesktop";
import MainNavigationMobile from "./MainNavigationMobile";
import { ToastContainer } from "react-toastify";


export default function RootLayout() {
    const navigation: any = { blocks: []};

    const scrollToSection = (navigationElement: any) => {
        window.scrollTo({
            top: navigationElement.current.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth'
        })
    }

	return (
		<>
            <MediaContextProvider>
                <Media at='mobile'>             
                    <MainNavigationMobile navigation={navigation} scrollToSection={scrollToSection}>
                        <Outlet context={[navigation, 0]} />
                    </MainNavigationMobile>
                </Media>
                <Media greaterThan='mobile'>
                    <MainNavigationDesktop navigation={navigation} scrollToSection={scrollToSection}>
                        <Outlet context={[navigation, 1]} />
                    </MainNavigationDesktop>
                </Media>
            </MediaContextProvider>
            <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
		</>
	);
}