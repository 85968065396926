import { Card, Divider, Image } from "semantic-ui-react";
import { PortfolioProject } from "../../models/PortfolioProject";

export default function PortfolioProjectCard(props: PortfolioProject) {
    console.log(props);
	return (
        <Card fluid style={{ boxShadow: 'none' }}
            onClick={() => {
                props.scrollToSection && props.scrollTargets && props.scrollTargetIndex !== undefined &&
                    props.scrollTargets[props.scrollTargetIndex] &&
                    props.scrollToSection(props.scrollTargets[props.scrollTargetIndex])
            }}>
            <Image src={props.image} />
            <Card.Content style={{ padding: "30px", backgroundColor: '#f7f7f7' }}>
                <Card.Header><h2>{props.header}</h2></Card.Header>
                <Divider hidden />
                <Card.Description>
                    <h3>{props.description}</h3>
                </Card.Description>
            </Card.Content>
        </Card>
	);
}