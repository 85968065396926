import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import './App.css'
import HomePage from './layout/HomePage';
import RootLayout from './layout/RootLayout';
import VisualCommunication from './layout/VisualCommunication';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children: [
            { index: true, element: <HomePage /> }
        ]
    }
    ,
    {
        path: '/portfolio',
        element: <VisualCommunication />
    }
]);

export default function App () {
    return <RouterProvider router={router} />
}
