import { Button, Header, Divider } from 'semantic-ui-react'
import { ContactForm } from '../../models/ContactForm';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import MyTextInput from '../../common/form/MyTextInput';
import MyFormGroup from '../../common/form/MyFormGroup';
import MyTextArea from '../../common/form/MyTextArea';
import { toast } from 'react-toastify';

interface Props {
    closeModal?: () => void;
}

export default function ContactUs({ closeModal } : Props) {

    const contactForm: ContactForm = { firstName: '', lastName: '', email: '', message: '' };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Enter first name'),
        lastName: Yup.string().required('Enter last name'),
        email: Yup.string().required('Enter email'),
        message: Yup.string().required('Enter message'),
    });

    const sendEmail = async (data: ContactForm) => {
        if (closeModal) closeModal();

        let ok = false;
        let message = '';
        try {
            let response = await fetch('https://sapphirebytes.com/api/contactus',
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });  
            if (response.status < 300) {
                ok = true;
            }
            else message += response.status;
        }
        catch (error: any) {
            // do nothing
            message += error;
        }   
        if (ok) {
            toast.success('Your message has been sent. Thank you for contacting us.');
        }
        else toast.error('Your message could not be sent. Please try it later. ' + message);
    }

    return (
        <>
            <Header as='h1'>Contact Us</Header>
            <Divider hidden />
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={contactForm}
                onSubmit={values => sendEmail(values)}
            >
                {({ handleSubmit }) => (
                    <Form className='ui form' onSubmit={handleSubmit}>
                        <MyFormGroup widths='equal'>
                            <MyTextInput name='firstName' placeholder='First name' />
                            <MyTextInput name='lastName' placeholder='Last name' />
                            <MyTextInput name='email' placeholder='E-mail' />
                        </MyFormGroup>
                        <MyTextArea rows={3} name='message' placeholder='Message' />

                        <Button
                            type='submit'
                            content='Send Message'
                            labelPosition='right'
                            icon='mail'
                            positive
                            size='big'
                        />
                    </Form>
                )}
            </Formik>
            
            
        </>
    )
}