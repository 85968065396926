import { PropsWithChildren } from "react";
import { Parallax } from "react-parallax";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { PortfolioProject } from "../../models/PortfolioProject";
import Slider from "react-slick";

var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    autoplay: true,
    easing: 'smooth',
    fade: true,

};

export default function PortfolioProjectDetails(props: PortfolioProject) {
	return (
		<div>
            <div>
                <Parallax
                    bgImage="/assets/images/portfolio/bg_gradient2.jpg"
                    strength={300}
                    renderLayer={percentage => (
                        <div
                            style={{ position: 'absolute', }}
                        />
                    )}
                >
                    <div style={{ marginTop: '16%', marginLeft: '10%', marginRight: '10%' }}>
                        <Grid stackable>
                            <Grid.Column verticalAlign='middle' width='5'>
                                <Container fluid className="portfolioBlock" textAlign='right'>
                                    <Header as='h1'>{props.header}</Header>
                                    <Container as='h3'>
                                        {props.description}
                                    </Container>
                                </Container>
                            </Grid.Column>
                            <Grid.Column verticalAlign='bottom' width='11'>
                                <Image src={props.detailImage} />
                            </Grid.Column>
                        </Grid>
                    </div>
                </Parallax>
            </div>
            <Container fluid>
                <Slider {...settings}>
                    {props.sliderImages.map(image => (
                        <div>
                            <img style={{ width: "100%" }} src={image} alt='' />
                        </div>
                    ))}
                </Slider>
            </Container>
		</div>
	);
}