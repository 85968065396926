import { PortfolioProject } from "../models/PortfolioProject"

export const portfolioProjects: PortfolioProject[] = [
    {
        image: '/assets/images/portfolio/baneball_thumb.jpg',
        header: 'Baneball',
        description: 'Art direction, UI/UX, complete 2D/3D content creation for a tactical sport mobile game.',
        detailImage: '/assets/images/portfolio/baneball_title.png',
        sliderImages: [ 
            '/assets/images/portfolio/baneball_1.jpg',
            '/assets/images/portfolio/baneball_2.jpg',
            '/assets/images/portfolio/baneball_3.jpg',
            '/assets/images/portfolio/baneball_4.jpg',
            '/assets/images/portfolio/baneball_5.jpg'
        ]
    }
    ,
    {
        image: '/assets/images/portfolio/falpro_thumb.jpg',
        header: 'falproLAB2',
        description: 'Catalog concept, layout, product photography and illustrations for high-end laboratory furniture brand.',
        detailImage: '/assets/images/portfolio/falco_title.png',
        sliderImages: [
            '/assets/images/portfolio/falco_2.jpg',
            '/assets/images/portfolio/falco_3.jpg',
            '/assets/images/portfolio/falco_4.jpg',
            '/assets/images/portfolio/falco_5.jpg',
            '/assets/images/portfolio/falco_6.jpg',
        ]
    }
    ,
    {
        image: '/assets/images/portfolio/spidertrouble_thumb.jpg',
        header: 'Spider Trouble',
        description: 'Art direction, UI/UX, complete 2D/3D content creation for an endless runner mobile game.',
        detailImage: '/assets/images/portfolio/spider_title.png',
        sliderImages: [
            '/assets/images/portfolio/spider_wallpaper1.jpg',
            '/assets/images/portfolio/spider_wallpaper2.jpg',
            '/assets/images/portfolio/spider_wallpaper3.jpg',
            '/assets/images/portfolio/spider_wallpaper4.jpg',
            '/assets/images/portfolio/spider_wallpaper5.jpg',
            '/assets/images/portfolio/spider_wallpaper6.jpg',
        ]
    }
    ,
    {
        image: '/assets/images/portfolio/nct_thumb.jpg',
        header: 'NCT',
        description: "3D visualization and presentations for Hungary's leading CNC manufacturer.",
        detailImage: '/assets/images/portfolio/nct_title.png',
        sliderImages: [
            '/assets/images/portfolio/nct1.jpg',
            '/assets/images/portfolio/nct2.jpg',
            '/assets/images/portfolio/nct3.jpg',
            '/assets/images/portfolio/nct4.jpg',
            '/assets/images/portfolio/nct5.jpg',
            '/assets/images/portfolio/nct6.jpg',
        ]
    }
    ,
    {
        image: '/assets/images/portfolio/heineken_thumb.jpg',
        header: 'Heineken',
        description: 'Design concept, 3D modeling and visualization for various POS promotion installations.',
        detailImage: '/assets/images/portfolio/heineken_title.png',
        sliderImages: [
            '/assets/images/portfolio/heineken_1.jpg',
            '/assets/images/portfolio/heineken_2.jpg',
            '/assets/images/portfolio/heineken_3.jpg',
            '/assets/images/portfolio/heineken_4.jpg',
            '/assets/images/portfolio/heineken_5.jpg',
            '/assets/images/portfolio/heineken_6.jpg',
            '/assets/images/portfolio/heineken_7.jpg',
            '/assets/images/portfolio/heineken_8.jpg',
            '/assets/images/portfolio/heineken_9.jpg',
        ]
    }
    ,
    {
        image: '/assets/images/portfolio/mokus_thumb.jpg',
        header: 'Mókus Sörkert',
        description: 'Print layout designs, photography and illustrations for a grill pub.',
        detailImage: '/assets/images/portfolio/mokus_title.png',
        sliderImages: [
            '/assets/images/portfolio/mokus_1.jpg',
            '/assets/images/portfolio/mokus_2.jpg',
            '/assets/images/portfolio/mokus_3.jpg',
            '/assets/images/portfolio/mokus_4.jpg',
            '/assets/images/portfolio/mokus_5.jpg',
            '/assets/images/portfolio/mokus_6.jpg',
        ]
    }
]