import { PropsWithChildren } from "react";
import { Form, SemanticWIDTHS } from "semantic-ui-react";

interface Props {
	widths : SemanticWIDTHS | 'equal'
}

export default function MyFormGroup(props : PropsWithChildren<Props>) {
	return (
		<Form.Group {...props}>
			{props.children}
		</Form.Group>
	);
}