import React, { PropsWithChildren } from "react";
import { Modal } from "semantic-ui-react";

interface Props {
    triggerObject: any;
}

export default function ModalWindow(props : PropsWithChildren<Props>) {
    const [open, setOpen] = React.useState(false)

    const closeModal = () => {
        setOpen(false)
    }

    const renderChildren = () => {
        return React.Children.map(props.children, (child: any) => {
            return React.cloneElement(child, {
                closeModal: closeModal,
            });
        });
    }

	return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.triggerObject}
        >
            <Modal.Content>
                {renderChildren()}
            </Modal.Content>
        </Modal>
	);
}