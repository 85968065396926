import { Button, Container, Menu, Image } from 'semantic-ui-react';
import ContactUs from "../features/contactus/ContactUs";
import { NavigationInfo } from '../models/NavigationInfo';
import ModalWindow from '../common/modal/ModalWindow';


export default function MainNavigationDesktop(props: React.PropsWithChildren<NavigationInfo>) {
    return (
        <>
        <Menu>
            <Container fluid className='underline' >
                <Container>
                    <Menu fluid size='large' borderless>
                        <Menu.Item position='left'><Image size='medium' src='/assets/images/sapphirebytes_logo_pro.png' />
                        </Menu.Item>
                        <Menu.Item className='fontsize1' position='right' as='a'
                            onClick={() => props.scrollToSection(props.navigation.blocks[0])}>
                            SAP
                        </Menu.Item>
                        <Menu.Item className='fontsize1' position='right' as='a'
                            onClick={() => props.scrollToSection(props.navigation.blocks[1])}>
                            App Development
                        </Menu.Item>
                        <Menu.Item className='fontsize1' position='right' as='a'
                            onClick={() => props.scrollToSection(props.navigation.blocks[2])}>
                            Visual Communication
                        </Menu.Item>
                            <Menu.Item position='right'>
                                <ModalWindow triggerObject={
                                    <Button primary style={{ margin: '0.5em' }}
                                    >
                                        Contact Us
                                    </Button>}
                                >
                                    <ContactUs/>
                                </ModalWindow>
                        </Menu.Item>
                    </Menu>
                </Container>
            </Container>
            </Menu>
            {props.children}
        </>
    )
}