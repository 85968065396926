import React from 'react';
import { Grid, Icon, Menu, Sidebar, Image } from 'semantic-ui-react';
import ContactUs from '../features/contactus/ContactUs';
import { NavigationInfo } from '../models/NavigationInfo';
import ModalWindow from '../common/modal/ModalWindow';


export default function MainNavigationMobile(props: React.PropsWithChildren<NavigationInfo>) {
    const [visible, setVisible] = React.useState(false)
    return (

        <>
            <Grid padded >
                <Grid.Row >
                    <Grid.Column className='underline'>
                        <Menu borderless secondary>
                            <Menu.Item position='left'><Image size='medium' src='/assets/images/sapphirebytes_logo_pro.png' />
                            </Menu.Item>
                            <Menu.Item position='right' onClick={() => setVisible(!visible)}>
                                <Icon name='sidebar' size='big' />
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='top'
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                >
                    <Menu.Item
                        className="sidebarmenu" as='a'
                        onClick={() => {
                            props.scrollToSection(props.navigation.blocks[0]);
                            setVisible(false)
                        }}>
                        SAP
                    </Menu.Item>
                    <Menu.Item
                        className="sidebarmenu" as='a'
                        onClick={() => {
                            props.scrollToSection(props.navigation.blocks[1])
                            setVisible(false)
                        }}>
                        App Developement
                    </Menu.Item>
                    <Menu.Item
                        className="sidebarmenu" as='a'
                        onClick={() => {
                            props.scrollToSection(props.navigation.blocks[2])
                            setVisible(false)
                        }}>
                        Visual Communication
                    </Menu.Item>
                    <Menu.Item className="sidebarmenu" as='a'>
                        <ModalWindow
                            triggerObject={<div>Contact Us</div>}
                        >
                            <ContactUs/>
                        </ModalWindow>
                    </Menu.Item>

                </Sidebar>
                <Sidebar.Pusher dimmed={visible}>
                    {props.children}
                </Sidebar.Pusher>
                </Sidebar.Pushable>
        </>
    )
}