import { Grid,  Container } from "semantic-ui-react";
import { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PortfolioProjectCard from "../features/portfolio/PortfolioProjectCard";
import PortfolioProjectDetails from "../features/portfolio/PortfolioProjectDetails";
import { portfolioProjects } from "../data/PortfolioData";


export default function VisualCommunication() {

    const scrollToSection = (navigationElement: any) => {
        console.log(navigationElement.current);
        window.scrollTo({
            top: navigationElement.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth'
        })
    }

    const detailRefs = useRef(new Array());

    return (
        <>
            <Container fluid>
                <Grid stackable centered columns={3} style={{ padding: "50px" }} relaxed>
                    <Grid.Row>
                        {portfolioProjects.map((project, index) => (
                            <Grid.Column key={index} className='GridPadding'>
                                <PortfolioProjectCard
                                    {...project}
                                    scrollToSection={scrollToSection}
                                    scrollTargets={detailRefs.current}
                                    scrollTargetIndex={index}
                                />
                            </Grid.Column>
                        ))}                                                        
                    </Grid.Row>
                </Grid>
            </Container>
            {portfolioProjects.map(project => (
                <div ref={(element) => detailRefs.current.push(element)}>
                    <PortfolioProjectDetails {...project}/>
                </div>
            ))}
        </>
    );
}