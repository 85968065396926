import { Grid, Image, Container, Header, Button } from "semantic-ui-react";
import { Parallax } from 'react-parallax';
import ContactUs from "../features/contactus/ContactUs";
import { useRef } from "react";
import { useOutletContext } from "react-router-dom";
import ModalWindow from "../common/modal/ModalWindow";

const texts: any =
    [
        {
            header: 'SAP',
            content: 
            `            
            <p>
            With more than 15 years of experience our team possesses highly professional skills in the ERP industry, particularly in e- Procurement and system integration.<br></br >
            </p >                      
            <p>
            Together with our customers we implement outstanding new solutions and optimize existing processes that serve the best business and technological needs of our clients.
            </p>
            `
        }
        ,
        {
            header: 'Mobile App Developement',
            content: 
            `            
            <p>                                       
            Be it business or gaming industry, we are experts in building great mobile applications. With 10 years of experience and more than 5 million downloads our team has all the necessary skills for implementing successful projects.                                                              
            </p>                                    
            <p>                                                    
            Besides our deep technical knowledge and outstanding artistic skills, we also possess a strong background in user acquisition and app store optimization.                                                   
            </p>                     
            `
        }
        ,
        {
            header: 'Visual Communication',
            content:
            `            
            <p>                
            Decades of practice in various digital arts including company branding, UI/UX, analog and 3D cinematography, visualization and product design.<br></br>
            </p>
            <p>                
            With wide spectrum of creative tools and assets we are more than capable to bring your vision to life.
            </p>                   
            `
        }
    ]

export default function HomePage() {
    const sapContainer = useRef(null);
    const appContainer = useRef(null);
    const visualContainer = useRef(null);
    const [navigation, sorting] = useOutletContext<any>();
    navigation.blocks = [sapContainer, appContainer, visualContainer];

    return (
        <>
            <div ref={sapContainer}>
               <Parallax
                    bgImage="/assets/images/bg_sapphire.jpg"
                    strength={200}
                    renderLayer={percentage => (
                        <div
                            style={{ position: 'absolute', }}
                        />
                    )}
                >
                    <Container fluid >
                        <Container style={{ padding: '4em 2em' }}>
                            <Grid stackable columns={2}>
                                <Grid.Column verticalAlign='middle'>
                                    <Container fluid >
                                        <Header as='h1'>{texts[0].header}</Header>
                                        <Container
                                            className='fontsize1'
                                            dangerouslySetInnerHTML={{ __html: texts[0].content }}>
                                        </Container>
                                    </Container>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src='/assets/images/sap.png' />
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </Container>
                </Parallax>
            </div >
            <div ref={appContainer}>
                <Container fluid className='mainbackground2' >
                    <Container style={{ padding: '4em 2em' }}>
                        {sorting === 1 ?
                            <Grid stackable columns={2}>
                                <Grid.Column>
                                    <Image src='/assets/images/gamedev.png' />
                                </Grid.Column>
                                <Grid.Column verticalAlign='middle'>
                                    <Container fluid >
                                        <Header as='h1'>{texts[1].header}</Header>
                                        <Container
                                            className='fontsize1'
                                            dangerouslySetInnerHTML={{ __html: texts[1].content }}>
                                        </Container>
                                    </Container>
                                </Grid.Column>
                            </Grid>
                            :
                            <Grid stackable columns={2}>
                                <Grid.Column verticalAlign='middle'>
                                    <Container fluid >
                                        <Header as='h1'>{texts[1].header}</Header>
                                        <Container
                                            className='fontsize1'
                                            dangerouslySetInnerHTML={{ __html: texts[1].content }}>
                                        </Container>
                                    </Container>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src='/assets/images/gamedev.png' />
                                </Grid.Column>
                            </Grid>
                        }
                    </Container>
                </Container>
            </div>
            <div ref={visualContainer}>
                <Parallax
                    bgImage="/assets/images/bg_sapphire.jpg"
                    strength={200}
                    renderLayer={percentage => (
                        <div
                            style={{ position: 'absolute', }}
                        />
                    )}
                >
                    <Container fluid>
                        <Container style={{ padding: '4em 2em' }}>
                            <Grid stackable columns={2}>
                                <Grid.Column verticalAlign='middle'>
                                    <Container fluid >
                                        <Header as='h1'>{texts[2].header}</Header>
                                        <Container className='fontsize1'
                                            dangerouslySetInnerHTML={{ __html: texts[2].content }}>
                                        </Container>
                                    </Container>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src='/assets/images/visual.png' />
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </Container>
                </Parallax>
            </div>

            <Container fluid className='mainbackground2'>
                <Container style={{ padding: '0em 2em' }}>
                    <Grid doubling centered verticalAlign='middle' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_1.png' />
                            </Grid.Column>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_2.png' />
                            </Grid.Column>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_3.png' />
                            </Grid.Column>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_4.png' />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_5.png' />
                            </Grid.Column>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_6.png' />
                            </Grid.Column>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_7.png' />
                            </Grid.Column>
                            <Grid.Column>
                                <Image className='refimage' src='/assets/images/partner_8.png' />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Container >
            <Container fluid className='mainbackground3'>
                <Container textAlign="center">
                    <Header as='h1' inverted>Feel free to get in touch!</Header>
                    <ModalWindow
                        triggerObject={
                            <Button size='massive' primary style={{ margin: '1.5em' }}
                            >
                                Contact Us
                            </Button>}
                    >
                        <ContactUs/>
                    </ModalWindow>
                </Container>
            </Container >

        </>
    )
}